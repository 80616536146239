<template>
  <v-container class="my-5" style="height: 100%">
    <v-row justify="center" align="center" style="height: 100%">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-3"
          contain
          width="450px"
          style="margin-left: auto; margin-right: auto" />
      </v-col>
      <v-col xl="3" lg="4" md="6" sm="8" xs="12">
        <validation-observer v-slot="{ handleSubmit, failed }">
          <v-form
            lazy-validation
            @submit.prevent="handleSubmit(submitForm)"
            style="display: flex; flex-direction: column">
            <validation-provider rules="required|email|max:200" v-slot="{ errors }" name="email">
              <v-text-field
                v-model="email"
                clearable
                :label="$t('login.email')"
                :error-messages="errors"></v-text-field>
            </validation-provider>
            <button-submit :failed="failed" :loading="loading" buttonText="buttons.send"></button-submit>
          </v-form>

          <p class="mt-16">
            {{ $t('login.noAccount') }}
            <router-link to="/registration" class="text-decoration-none" style="font-weight: bold; color: blue">
              {{ $t('login.registerHere') }}
            </router-link>
          </p>

          <p class="mt-5">
            {{ $t('registration.haveAccount') }}
            <router-link to="/login" class="text-decoration-none" style="font-weight: bold; color: blue">
              {{ $t('registration.loginHere') }}
            </router-link>
          </p>
        </validation-observer>
      </v-col>
    </v-row>

    <v-dialog v-model="showSuccessDialog" max-width="500px">
      <v-card>
        <v-card-title class="info title white--text font-weight-bold">{{ $t('setPassword.success') }}</v-card-title>
        <v-spacer></v-spacer>
        <v-card-text style="font-size: 16px; padding-top: 16px">
          {{ $t('setPassword.checkEmail') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeSuccessDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import i18n from '@/i18n/i18n';

export default {
  name: 'EnterEmailPage',
  components: { ButtonSubmit },
  data() {
    return {
      email: '',
      user: null,
      loading: false,
      showSuccessDialog: false,
    };
  },
  methods: {
    submitForm() {
      this.loading = true;
      this.$store
        .dispatch('forgetPassword/sendForgetPasswordEmail', { email: this.email })
        .then((res) => {
          if (res.success) {
            this.showSuccessDialog = true;
          }
        })
        .catch((e) => {
          if (e.response.status === 422) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.userEmailDoesNotExists'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeSuccessDialog() {
      this.showSuccessDialog = false;
    },
  },
};
</script>
